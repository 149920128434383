import React from 'react';
import logo from './../../images/srh-logo.png';
const Footer = () => {
    return (
        <footer className="footer">
            {/* <!-- Start Footer Area --> */}
            <footer className="footer">
                {/* <!-- Footer Top --> */}
                <div className="footer-top section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                {/* <!-- Single Widget --> */}
                                <div className="single-footer about">
                                    <div className="logo">
                                        {/* <a href="index.html"><img src="http://srhjewellery.com/public/photos/srh-logo.png" alt="#"/></a> */}
                                        <a href="index.html"><img src={logo} alt="#" /></a>
                                    </div>
                                    <p className="text"> Welcome to SRH Jewellery, your premier destination for products. Discover a curated collection of product types designed to elevate your needs or lifestyle. Dive into a world of quality, style, innovation where every purchase guarantees satisfaction. </p>
                                    <p className="call">Got Question? Call us 24/7<span><a href="tel:123456789"> +1000000001 </a></span></p>
                                </div>
                                {/* <!-- End Single Widget --> */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                {/* <!-- Single Widget --> */}
                                <div className="single-footer links">
                                    <h4>Information</h4>
                                    <ul>
                                        <li><a href="/about-us">About Us</a></li>
                                        <li><a href="/faq">Faq</a></li>
                                        <li><a href="/terms-condition">Terms & Conditions</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    </ul>
                                </div>
                                {/* <!-- End Single Widget --> */}
                            </div>
                            {/* <div className="col-lg-2 col-md-6 col-12">
                                <div className="single-footer links">
                                    <h4>Customer Service</h4>
                                    <ul>
                                        <li><a href="#">Payment Methods</a></li>
                                        <li><a href="#">Money-back</a></li>
                                        <li><a href="#">Returns</a></li>
                                        <li><a href="#">Shipping</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-md-6 col-12">
                                {/* <!-- Single Widget --> */}
                                <div className="single-footer social">
                                    <h4>Get In Tuch</h4>
                                    {/* <!-- Single Widget --> */}
                                    <div className="contact">
                                        <ul>
                                            <li> Address- 146, Vinodilal Gali,
                                                Teja Chowk,
                                                Near Paanch Batti,
                                                Beawar. (Raj) </li>
                                            <li> srhjewellerybwr@gmail.com </li>
                                            <li> 305901 </li>
                                        </ul>
                                    </div>
                                    {/* <!-- End Single Widget --> */}
                                    <div className="sharethis-inline-follow-buttons"></div>
                                </div>
                                {/* <!-- End Single Widget --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Footer Top --> */}
                <div className="copyright">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="left">
                                        <p>© 2024 Developed By Arambh Technologies  -  All Rights Reserved.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="right">
                                    <a href="index.html"><img src={logo} alt="#" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- /End Footer Area --> */}
        </footer>
    );
};

export default Footer;
