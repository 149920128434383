// ModalComponent.js
import React , {useState} from "react";
import { Modal, Button, Form } from "react-bootstrap";
import '../../pages/checkoutModel/methodsModel.css';

const MethodModalComponent = ({
  show,
  handleClose,
  header,
  description,
  icon,
  radioOptions,
  selectedOption,
  setSelectedOption,
}) => {
  const [selectedValue, setSelectedvalue] = useState(false);
  return (
    <Modal show={show} onHide={handleClose} centered className="modelCheckout">
      <Modal.Header >
       
      </Modal.Header>
      <Modal.Body style={{padding:"2rem"}}>
      <Modal.Title><i className={icon} style={{ marginRight: '8px' }}></i>
      {header}</Modal.Title>
        <p>{description}</p>
        <Form>
          {radioOptions.map((option, index) => (
            <Form.Check
              key={index}
              type="radio"
              label={option.label}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => setSelectedvalue(option.value, header)} // Pass the selected value back to the parent
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer style={{padding:"0.5rem 2rem"}}>
        <Button variant="secondary" onClick={handleClose} style={{padding:"5px 20px",textTransform: "none"}}>
          Close
        </Button>
        <Button
        style={{padding:"5px 20px",textTransform: "none"}}
          variant="primary"
          onClick={() => setSelectedOption(selectedValue,header)} // Confirm the selection
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MethodModalComponent;
