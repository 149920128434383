
import React, { useState, useEffect } from 'react';
// import "../components/style.css";
// index.js or App.js
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./../../css/responsive.css";
// import "./../../css/style.css";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../header/header.css';
import { getCategoryWithChildren } from './../../services/categoryService';
import logo from './../../images/srh-logo.png';
import { getAuthInfo, getProfileInfo } from '../../services/authService';

const Header = () => {
    const wishlistCount = 1;
    const CartCount = 5;

const [categories, setCategories] = useState([]);
const [authInfo, setAuthInfo] = useState({});

    const [openDropdown, setOpenDropdown] = useState(null);
    const handleMouseEnter = (index) => setOpenDropdown(index);
    const handleMouseLeave = () => setOpenDropdown(null);

  const checkLoggedInInfo = async () => {
    try {
        const result = await getAuthInfo()
        if(result?.token) setAuthInfo(result);
        } catch (error) {
            console.error('Error fetching authInfo:', error);
        }
    };

  const loadCategories = async () => {
    try {
        const {data} = await getCategoryWithChildren()
        console.log("setCategories", data);
        setCategories(data);
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
    };
  const handleLogout = async () => {
    try {
        localStorage.clear();
        setAuthInfo({});
    } catch (error) {
    console.error('Error fetching banners:', error);
    }
};

  useEffect(() => {
    loadCategories();
    checkLoggedInInfo();
  }, []);

    return (
        <header className="header shop">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">

                            <div className="top-left">
                                <ul className="list-main">
                                    {/* <li>Upload  <i className="fa fa-camera"></i></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="right-content">
                                <ul className="list-main">
                                    <li> <a href="#">Sell With Us</a></li>
                                    {/* <li> <a href="#">Offer For You</a></li> */}
                                    <li> <a href="/contact">Connect</a></li>
                                    <li> <a href="/about-us">About</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="middle-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-12">
                            {/* <!-- Logo --> */}
                            <div className="logo">
                                {/* <a href=""><img src="http://srhjewellery.com/public/photos/srh-logo.png" alt="logo" /></a> */}
                                <a href=""><img src={logo} alt="logo" /></a>
                            </div>
                            {/* <!--/ End Logo --> */}
                            {/* <!-- Search Form --> */}
                            <div className="search-top">
                                <div className="top-search"><a href="#0"><i className="search"></i></a></div>
                                {/* <!-- Search Form --> */}
                                <div className="search-top">
                                    <form className="search-form">
                                        <input type="text" placeholder="Search here..." name="search" />
                                        <button value="search" type="submit"><i className="search"></i></button>
                                    </form>
                                </div>
                                {/* <!--/ End Search Form --> */}
                            </div>
                            {/* <!--/ End Search Form --> */}
                            <div className="mobile-nav"></div>
                        </div>
                        <div className="col-lg-6 col-md-5 col-12">
                            <div className="search-bar-top">
                                <div className="search-bar" style={{ width: '100%' }}>
                                    {/* <!-- <select>
                                        <option >All Category</option>
                                        <option>Bangles</option>
                                        <option>Chains</option>
                                        <option>Earrings</option>
                                    </select> --> */}
                                    <form method="POST" action="/product/search">
                                        <input type="hidden" name="_token" value="Sin5WiVKfgn3E386VRkz7p5IONQ33i62W00avwEm" />                                <input name="search" placeholder="Search Products Here....." type="search" />
                                        <button className="btnn" type="submit" ><i className="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 col-12">
                            <div className="right-bar">
                                {/* <!-- Search Form --> */}
                                <div className="sinlge-bar shopping">
                                    {
                                        !authInfo?.token && (
                                            <><a href="/login" className="single-icon"><span className="total-count1" style={{ fontSize: '13px' }}>Login</span></a><a href="/register" className="single-icon"><span className="total-count1" style={{ fontSize: '13px' }}>Account</span></a></>
                                        )
                                    }
                                    {
                                        authInfo?.token && (
                                            <><a href="/profile" className="single-icon"><span className="total-count1" style={{ fontSize: '13px' }}>{authInfo?.name}</span></a><a href="#" className="single-icon"><span className="total-count1" style={{ fontSize: '13px' }} onClick={handleLogout}>Logout</span></a></>
                                        )
                                    }

                                    <a href="/wishlist" className="single-icon"><i className="fa fa-heart-o"></i> <span className="total-count">0</span></a>
                                </div>

                                <div className="sinlge-bar shopping">
                                    <a href="/cart" className="single-icon"><i className="fa fa-shopping-bag"></i> <span className="total-count">0</span></a>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNavDarkDropdown"
                                    aria-controls="navbarNavDarkDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"><i className="fa fa-list-ul" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-inner">
                <div className="container">
                    <div className="cat-nav-head">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="menu-area">
                                    {/* <!-- Main Menu --> */}
                                    <nav className="navbar navbar-expand-lg">

                                        <div className="collapse  navbar-collapse" id="navbarNavDarkDropdown">
                                            <div className="nav-inner w-100">
                                                <ul className="nav main-menu menu navbar-nav">
                                                    <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                                                        <ul className="navbar-nav w-100" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                            <li className=""><a href="/">Home</a></li>
                                                            {categories.map((item, index) => (
                                                                <li className="nav-item dropdown"
                                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                                    onMouseLeave={handleMouseLeave}>
                                                                    <a
                                                                        className="nav-link dropdown-toggle"
                                                                        href="#"
                                                                        id={`navbarDarkDropdownMenuLink${index}`}
                                                                        role="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded={openDropdown === index}
                                                                    >
                                                                        {item.title}
                                                                    </a>
                                                                    <ul
                                                                        className={`dropdown-menu dropdown-menu-dark ${openDropdown === index ? 'show' : ''}`}
                                                                        aria-labelledby={`navbarDarkDropdownMenuLink${index}`}
                                                                        style={{ maxHeight: '300px', overflowY: 'auto' }}
                                                                    >
                                                                        {item['children'].map((childItem, childIndex) => (
                                                                            <li key={childItem?._id ?? childIndex}>
                                                                                <a className="dropdown-item" href={`/product-grids?parent=${item?.slug}&category=${childItem?.slug}`} style={{ fontSize: '12px', padding: '0px 10px' }}>
                                                                                    {childItem?.title ?? childItem}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                            <li className=""><a href="/product-grids">More</a></li>
                                                        </ul>
                                                    </div>

                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
