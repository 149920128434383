import React, {useState, useEffect} from 'react';
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { 
    getCartWithPagination, 
    createCart, 
    getCartById, 
    updateCart, 
    deleteCart 
  } from './../../services/cartService'; 

const Cart = () => {
  
  const [cart, setCart] = useState([]);
  const [error, setError] = useState('');

  const fetchCartWithPagination = async () => {
    try {
      const data = await getCartWithPagination();
      console.log("data==========>",data);
      setCart(data?.data); // Assuming the API returns an array of cart items
    } catch (error) {
      setError('Error fetching cart items.');
      console.error(error);
    }
  };

    // Fetch paginated cart items on component mount and when currentPage changes
    useEffect(() => {
        fetchCartWithPagination();
      }, []);

//   const handleCreateCart = async () => {
//     try {
//       await createCart();
//       fetchCartWithPagination(); // Refresh cart list after creation
//     } catch (error) {
//       setError('Error creating cart.');
//       console.error(error);
//     }
//   };

//   const handleGetCartById = async (categoryId) => {
//     try {
//       const cart = await getCartById(categoryId);
//       console.log('Cart Details:', cart);
//     } catch (error) {
//       setError('Error fetching cart by ID.');
//       console.error(error);
//     }
//   };

//   const handleUpdateCart = async (categoryId) => {
//     try {
//       await updateCart(categoryId);
//       fetchCartWithPagination(); // Refresh cart list after update
//     } catch (error) {
//       setError('Error updating cart.');
//       console.error(error);
//     }
//   };

//   const handleDeleteCart = async (categoryId) => {
//     try {
//       await deleteCart(categoryId);
//       fetchCartWithPagination(); // Refresh cart list after deletion
//     } catch (error) {
//       setError('Error deleting cart.');
//       console.error(error);
//     }
//   };

    // Data for the table rows if available
    const tableRows = cart?.products?.length > 0 ? (
        cart?.products.map((item, index) => (
            <tr key={index}>
                <td className="text-center">{item?.product?.title}</td>
                <td className="text-center">{item?.product?.price}</td>
                <td className="text-center">{item?.qty}</td>
                <td className="text-center">{item?.product?.price * item?.qty}</td>
                <td className="text-center"><i className="fas fa-trash-o"></i></td>
            </tr>
        ))
    ) : (
        <tr>
            <td className="text-center" colSpan="6">
                There are no carts available. <a href="/product-grids" style={{ color: "blue" }}>Continue shopping</a>
            </td>
        </tr>
    );


    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li><a href="home">Home<i className="fas fa-arrow-right"></i></a></li>
                                    <li className="active"><a href="">Cart</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumbs --> */}
            {/* <!-- Shopping Cart --> */}
            <div className="shopping-cart section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Shopping Summery --> */}
                            <table className="table shopping-summery">
                                <thead style={{width:"100%"}}>
                                    <tr className="main-hading">
                                        <th style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>PRODUCT</th>
                                        {/* <th  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>NAME</th> */}
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>UNIT PRICE</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>QUANTITY</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>TOTAL</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}><i className="fas fa-trash-o"></i></th>
                                    </tr>
                                </thead>
                                <tbody id="cart_item_list">
                {tableRows}
            </tbody>
                            </table>
                            {/* <!--/ End Shopping Summery --> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Total Amount --> */}
                            <div className="total-amount">
                                <div className="row">
                                    <div className="col-lg-8 col-md-5 col-12">
                                        <div className="left">
                                            <div className="coupon">
                                                <form action="/coupon-store" method="POST">
                                                    <input type="hidden" name="_token" value="Sin5WiVKfgn3E386VRkz7p5IONQ33i62W00avwEm" />											<input name="code" placeholder="Enter Valid Coupon" />
                                                    <button className="btn">Apply Coupon</button>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-12">
                                        <div className="right">
                                            <ul>
                                                <li className="order_subtotal" data-price="0">Cart Subtotal<span>{cart?.totalAmount}</span></li>

                                                <li className="last" id="order_total_price">You Pay<span>{cart?.totalAmount}</span></li>
                                            </ul>
                                            <div className="button5">
                                                <a href="/checkout" className="btn">Checkout</a>
                                                <a href="/product-grids" className="btn">Continue shopping</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--/ End Total Amount --> */}
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <!--/ End Shopping Cart --> */}
        </div>


    );
};

export default Cart;
