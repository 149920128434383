import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import './productCarousel.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ProductModal from '../productModel/productModel.js';
import { updateWishlist } from '../../services/wishlistService';
import { useNavigate } from 'react-router-dom';



const ProductCarousel = ({title, description, products}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const navigate = useNavigate();
  const handleShowModal = (content) => {
    setShowModal(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const handleWishlist = async (productId,type) => {
    try {
        console.log("productId", productId)
    const result = await updateWishlist({productId: productId, type: type})
    if(!result) navigate("/login");
    } catch (error) {
    console.error('Error fetching banners:', error);
    }
};

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block'  }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 200,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-area most-popular">
      <div className="container">
        <div className="row" >
          <div className="col-12"  >
            <div className="section-title" style={{ marginBottom: "35px" }}>
              <h2>{title}</h2>
              <span>{description}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              {products.map((product, index) => (
                <div className="single-product-corosal" key={index} style={{ width: "285px !important", margin: "0 10px" }}>
                  <div className="product-img">
                    <a href={`/product-detail/${product?.slug}`}>
                      <img className="default-img" src={product?.image} alt={product?.title} />
                      <img className="hover-img" src={product?.image} alt={product?.title} />
                    </a>
                    <div className="button-head">
                      <div className="product-action">

                        <i className="fas fa-eye" title="Quick Shop" onClick={() => handleShowModal(product)} style={{ marginRight: "10px" }}></i>

                        {/* <a title="Wishlist" href={`/wishlist/${product?.slug}`}>
                          <i className="fas fa-heart" title="Add to Wishlist" style={{ paddingRight: "10px" }}></i>
                          
                        </a> */}
                        <a onClick={() => handleWishlist(product?.slug,product?.isWishlist ?  'remove' : 'add')}>
                                                            { product?.isWishlist && (<i className="fa fa-heart-o" style={{color:'red',paddingRight: "10px"}}></i>)}
                                                            { !product?.isWishlist && (<i className="fa fa-heart-o" style={{ paddingRight: "10px" }}></i>)}
                                                            </a>
                      </div>
                      <div className="product-action-2">
                        <a href={`/add-to-cart/${product?.slug}`}>Add to cart</a>
                      </div>
                    </div>
                  </div>
                  <div className="product-content">
                    <h3> <a href={`/product-detail/${product?.slug}`}>{product?.title}</a></h3>
                    <div className="product-price">
                      <span className="old">{product?.price}</span>
                      <span>{product?.discountPrice}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-sm-right">
            <a href="/product-grids" className="button buttonCss">View All</a>
          </div>
        </div>
      </div>
      <ProductModal showModal={showModal} handleCloseModal={handleCloseModal} modalContent={modalContent} />
       
    </div>
  );
};

export default ProductCarousel;
