import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import '../checkOut/checkOut.css';
import ModalComponent from '../../pages/checkoutModel/methodsModel.js'
import { clippingParents } from "@popperjs/core";

// Validation schema
const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    shipping_address_1: Yup.string().required("Address 1 is required"),
    shipping_city: Yup.string().required("City is required"),
    shipping_postcode: Yup.string().required("Postcode is required"),
    shipping_country_id: Yup.string().required("Country is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    agree: Yup.bool().oneOf([true], "You must agree to the Privacy Policy"),
});

const CheckOut = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        // resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        console.log("Form Data:", data);
        console.log("Register Account:", registerAccount);
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedShippingMethod, setSelectedShippingMethod] = useState("");
    const [registerAccount, setRegisterAccount] = useState(true);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const [modalContent, setModalContent] = useState({});

    // Function to open the modal with specific content
    const openModal = (header, description, icon, options) => {
        setModalContent({ header, description, icon, options });
        setShowModal(true);
    };

    const handleSelectPaymentMethod = (value, header) => {
        console.log(value, header)
        if (header == "Shipping Method"
        ) {
            setSelectedShippingMethod(value);
        }
        else {
            setSelectedPaymentMethod(value);
        }
        setShowModal(false); // Close the modal after selection
    };

    return (
        <div>
            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li>
                                        <a href="/home">
                                            Home<i className="fas fa-arrow-right"></i>
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a href="/">Checkout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row col-12" style={{ padding: "25px" }}>
                <p className="mb-3">
                    If you already have an account with us, please login at the{" "}
                    <a href="/login">
                        <strong>login page</strong>
                    </a>
                    .
                </p>
                <div className="col-md-7 mb-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                            <legend className="checkoutTitle">Your Personal Details</legend>
                            <div className="row row-cols-1 row-cols-md-2">
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name="account"
                                        value="1"
                                        id="input-register"
                                        className="form-check-input"
                                        checked={registerAccount}
                                        onChange={() => setRegisterAccount(true)} // Set state to true
                                        disabled=""
                                    />
                                    <label htmlFor="input-register" className="form-check-label checkoutLabel">
                                        Register Account
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name="account"
                                        value="0"
                                        id="input-guest"
                                        className="form-check-input"
                                        checked={!registerAccount}
                                        onChange={() => setRegisterAccount(false)} // Set state to false
                                        disabled=""
                                    />
                                    <label htmlFor="input-guest" className="form-check-label checkoutLabel">
                                        Guest Checkout
                                    </label>
                                </div>


                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">First Name</label>
                                    <Controller
                                        name="firstname"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="First Name"
                                                className={`form-control ${errors.firstname ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.firstname?.message}
                                    </div>
                                </div>

                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Last Name</label>
                                    <Controller
                                        name="lastname"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Last Name"
                                                className={`form-control ${errors.lastname ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.lastname?.message}
                                    </div>
                                </div>

                                <div className="col mb-3">
                                    <label className="form-label checkoutLabel">E-Mail</label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="E-Mail"
                                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset id="shipping-address">
                            <legend className="checkoutTitle">Shipping Address</legend>
                            <div className="row row-cols-1 row-cols-md-2">
                                <div className="col mb-3">
                                    <label className="form-label checkoutLabel">Company</label>
                                    <Controller
                                        name="shipping_company"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input {...field} type="text" placeholder="Company" className="form-control" />
                                        )}
                                    />
                                </div>
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Address 1</label>
                                    <Controller
                                        name="shipping_address_1"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Address 1"
                                                className={`form-control ${errors.shipping_address_1 ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.shipping_address_1?.message}
                                    </div>
                                </div>
                                <div className="col mb-3">
                                    <label className="form-label checkoutLabel">Address 2</label>
                                    <Controller
                                        name="shipping_address_2"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input {...field} type="text" placeholder="Address 2" className="form-control" />
                                        )}
                                    />
                                </div>
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">City</label>
                                    <Controller
                                        name="shipping_city"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="City"
                                                className={`form-control ${errors.shipping_city ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.shipping_city?.message}
                                    </div>
                                </div>
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Post Code</label>
                                    <Controller
                                        name="shipping_postcode"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                placeholder="Post Code"
                                                className={`form-control ${errors.shipping_postcode ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.shipping_postcode?.message}
                                    </div>
                                </div>
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Country</label>
                                    <Controller
                                        name="shipping_country_id"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className={`form-select ${errors.shipping_country_id ? "is-invalid" : ""
                                                    }`}
                                            >
                                                <option value=""  > --- Please Select --- </option>
                                                <option value="244">Aaland Islands</option>
                                                <option value="1">Afghanistan</option>
                                                <option value="2">Albania</option>
                                            </select>
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.shipping_country_id?.message}
                                    </div>
                                </div>
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Region / State</label>
                                    <Controller
                                        name="shipping_country_id"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className={`form-select ${errors.shipping_country_id ? "is-invalid" : ""
                                                    }`}
                                            >
                                                <option value=""> --- Please Select --- </option>
                                                <option value="244">Aaland Islands</option>
                                                <option value="1">Afghanistan</option>
                                                <option value="2">Albania</option>
                                            </select>
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.shipping_country_id?.message}
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend className="checkoutTitle">Your Password</legend>
                            <div className="row">
                                <div className="col mb-3 required">
                                    <label className="form-label checkoutLabel">Password</label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="password"
                                                placeholder="Password"
                                                className={`form-control ${errors.password ? "is-invalid" : ""
                                                    }`}
                                            />
                                        )}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.password?.message}
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div className="form-check form-switch form-switch-lg">
                            <Controller
                                name="subscribe"
                                control={control}
                                render={({ field }) => (
                                    <input {...field} type="checkbox" className="form-check-input" />
                                )}
                            />
                            <div className="invalid-feedback">{errors.subscribe?.message}</div>
                            <label className="form-check-label ml-2 ">
                                I wish to subscribe to the Your Store newsletter.
                            </label>

                        </div>
                        <div className="form-check form-switch form-switch-lg">
                            <Controller
                                name="agree"
                                control={control}
                                render={({ field }) => (
                                    <input {...field} type="checkbox" className="form-check-input" />
                                )}
                            />
                            <div className="invalid-feedback">{errors.agree?.message}</div>
                            <label className="form-check-label ml-2 ">
                                I have read and agree to the{" "}
                                <a href="https://demo.opencart.com/en-gb/privacy/info" className="modal-link">
                                    <b>Privacy Policy</b>
                                </a>
                            </label>

                        </div>
                        <div className="buttons clearfix">
                            <button type="submit" className="btn btn-primary btn-sm mt-3">
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-5 mb-3">
                    <div id="checkout-payment-method" className=" ">
                        <fieldset>
                            <legend className="checkoutTitle">Shipping Method</legend>
                            <div className="input-group">
                                <span className="input-group-text"><i className="fa fa-truck"></i></span>
                                <input type="text" name="payment_method" value={selectedShippingMethod} placeholder="Choose payment method..." id="input-payment-method" className="form-control" readonly="" />
                                <button type="button" id="button-payment-methods" className="btn btn-primary" style={{ padding: "5px 20px", textTransform: "none" }} onClick={() =>
                                    openModal(
                                        "Shipping Method",
                                        "Please select your preferred shipping method.",
                                        "fa fa-truck",
                                        [
                                            { label: "Standard Shipping", value: "Standard" },
                                            { label: "Express Shipping", value: "Express" },
                                        ]
                                    )
                                } > Choose</button>
                            </div>
                            <input type="hidden" name="code" value="" id="input-payment-code" />

                        </fieldset>
                        <br />


                    </div>
                    <div id="checkout-payment-method" className=" ">
                        <fieldset>
                            <legend className="checkoutTitle">Payment Method</legend>
                            <div className="input-group">
                                <span className="input-group-text"><i className="fa fa-credit-card"></i></span><input type="text" name="payment_method" value={selectedPaymentMethod} placeholder="Choose payment method..." id="input-payment-method" className="form-control" readonly="" />
                                <button type="button" id="button-payment-methods" className="btn btn-primary" style={{ padding: "5px 20px", textTransform: "none" }} onClick={() =>
                                    openModal(
                                        "Payment Method",
                                        "Please select your preferred payment method.",
                                        "fa fa-credit-card",
                                        [
                                            { label: "Credit Card", value: "Credit Card" },
                                            { label: "PayPal", value: "PayPal" },
                                            { label: "Bank Transfer", value: "Bank Transfer" },
                                        ]
                                    )
                                }>Choose</button>
                            </div>
                            <input type="hidden" name="code" value="" id="input-payment-code" />

                        </fieldset>
                        <br />
                        <div className="mb-3">
                            <label for="input-comment" className="form-label"><strong>Add Comments About Your Order</strong></label> <textarea name="comment" rows="4" id="input-comment" className="form-control"></textarea>
                        </div>

                    </div>
                    <div id="checkout-confirm"><div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td className="text-start">Product Name</td>
                                    <td className="text-end">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-start">1x <a href="https://demo.opencart.com/en-gb/product/macbook">MacBook</a>
                                        <br />
                                        <small> - Reward Points: 600</small>
                                    </td>
                                    <td className="text-end">$602.00</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="text-end"><strong>Sub-Total</strong></td>
                                    <td className="text-end">$500.00</td>
                                </tr>
                                <tr>
                                    <td className="text-end"><strong>Eco Tax (-2.00)</strong></td>
                                    <td className="text-end">$2.00</td>
                                </tr>
                                <tr>
                                    <td className="text-end"><strong>VAT (20%)</strong></td>
                                    <td className="text-end">$100.00</td>
                                </tr>
                                <tr>
                                    <td className="text-end"><strong>Total</strong></td>
                                    <td className="text-end">$602.00</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                        <div id="checkout-payment">
                            <div className="text-end"><button type="button" className="btn btn-primary" disabled="">Confirm Order</button></div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <ModalComponent
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    header={modalContent.header}
                    description={modalContent.description}
                    icon={modalContent.icon}
                    radioOptions={modalContent.options}
                    selectedOption={modalContent.header == "Shipping Method" ? selectedShippingMethod : selectedPaymentMethod}
                    setSelectedOption={handleSelectPaymentMethod}
                />
            )}
        </div>
    );
};

export default CheckOut;


