import React, { useEffect, useState } from 'react';
import "../../css/bootstrap.css";
import { useNavigate, useParams } from 'react-router-dom';
import { getProductById, getProductWithPagination } from '../../services/productService';
import { createCart } from '../../services/cartService';
import ProductCarousel from './../../pages/productCarousel/productCarousel.js';
import { getAuthInfo } from '../../services/authService';
import { updateWishlist } from '../../services/wishlistService';
import ReviewModel from '../../pages/review/review.js';
import { getReviewWithPagination } from '../../services/reviewService';

// const productData = {
//     product: {
//       name: "Adorable Heart Necklace",
//       img: "/public/photos/product/product3.webp",
//       discountPrice: "$63.00",
//       originalPrice: "$70.00",
//       description: "RETRO STYLING. Designed with Retro style in mind, this slim straight jean provides a vintage look for the modern man.",
//       sizeOptions: ["XL"],
//       slug: "adorable-heart-necklace",
//       category: "Chains",
//       stockStatus: "Available",
//       stockCount: 18,
//       size: "XL"
//     }
//   };

const ProductDetail = ({ }) => {
    const { productName: slug } = useParams();
    const [qty, setQty] = useState(1);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [activeTab, setActiveTab] = useState('description');
    const [products, setProducts] = useState([]);
    const [productData, setProductData] = useState({});
    const [reviews, setReview] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const navigate = useNavigate();
    const handleShowModal = (content) => {
        setShowModal(true);
        setModalContent(content);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent(null);
    };

    //const product = productData.product;
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const loadProducts = async () => {
        try {
            const result = await getProductWithPagination()
            setProducts((prevItems) => [...result?.data?.data]);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const getProductId = async () => {
        try {
            const result = await getProductById(slug)
            console.log('result======>', result);
            setProductData(result?.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const getReviews = async () => {
        try {
            const result = await getReviewWithPagination(1, 100, { productId: slug });
            if(result){
                setReview((prevItems) => [...result?.data?.data]);
            }
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const handleWishlist = async (productId, type) => {
        try {
            console.log("productId", productId)
            const result = await updateWishlist({ productId: productId, type: type })
            if(!result) navigate('/login');
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const checkLoggedIn = async () => {
        try {
            const result = await getAuthInfo()
            if (result?.token) setLoggedIn(true);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const addToCart = async () => {
       const result =  await createCart(qty, slug);
       if(!result) navigate('/login');
    };

    const increaseQty = async () => {
        console.log("increaseQty triggered")
        setQty(qty + 1)
    };
    const decreaseQty = async () => {
        console.log("decreaseQty triggered")
        if (qty > 1) setQty(qty - 1)
    };

    const [productDetail, setProductDetail] = useState({});

    const loadProduct = async () => {
        try {
            const response = await getProductById(slug)
            const { data } = response;
            console.log("data", data)
            setProductDetail(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        loadProduct();
        loadProducts();
        checkLoggedIn();
        getReviews();
        getProductId();
    }, []);

    if (!productData) {

        return <div>Loading...</div>;
    }



    return (
        <div>
            {/* // <!-- Breadcrumbs --> */}
            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
                                    <li className="active"><a href="">Shop Details</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // <!-- End Breadcrumbs -- */}
            {/* <!-- Shop Single --> */}
            <section className="shop single section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12" style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    {/* <!-- Product Slider --> */}
                                    <div className="product-gallery">
                                        {/* <!-- Images slider --> */}
                                        <div className="flexslider-thumbnails">
                                            <ul className="slides">
                                                <li data-thumb={productDetail?.image} rel="adjustX:10, adjustY:">
                                                    <img src={productDetail?.image} alt={productDetail?.title} />
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <!-- End Images slider --> */}
                                    </div>
                                    {/* <!-- End Product slider --> */}
                                    <div className="col-lg-6 col-12">
                                        <div className="product-des">
                                            {/* <!-- Description --> */}
                                            <div className="short">
                                                <h4>{productDetail?.title}</h4>
                                                <div className="rating-main">
                                                    <ul className="rating">
                                                        {[...Array(5)].map((_, index) => (
                                                            <li key={index}>
                                                                {/* <i className={`fa ${index < productData?.avgRating ? 'fa-star' : 'fa-star-o'}`}></i> */}
                                                                <i className={'fa fa-star-o'}></i>
                                                            </li>
                                                        ))}
                                                    </ul>

                                                    <a href="#" className="total-review">({productData?.totalReview}) Review</a>
                                                </div>
                                                <p className="price"><span className="discount">{productDetail?.discountPrice}</span><s>{productDetail?.price}</s> </p>
                                                <p className="description"><p><span style={{ color: 'rgb(15, 17, 17)', fontFamily: '"Amazon Ember", Arial, sans-serif', fontSize: '14px' }}> {productData?.description}</span><br /></p></p>
                                            </div>
                                            {/* <!--/ End Description --> */}
                                            {/* <div className="size">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <h5 className="title">Size</h5>
                                                        <select>
                                                        {productDatasizeOptions.map((size, index) => (
                                                            <option key={index}>{size}</option>
                                                        ))}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div> */}
                                            {/* <!-- Product Buy --> */}
                                            <div className="product-buy">
                                                <form>
                                                    <div className="quantity">
                                                        <h6>Quantity :</h6>
                                                        {/* <!-- Input Order --> */}
                                                        <div className="input-group">
                                                            <div className="button minus">
                                                                <button type="button" className="btn btn-primary btn-number" onClick={decreaseQty} data-type="minus" data-field="quant[1]">
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                            </div>

                                                            <input type="hidden" name="slug" value="adorable-heart-necklace" />

                                                            <input type="text" name="quant[1]" className="input-number" data-min="1"
                                                                data-max="1000" value={qty} id="quantity" />

                                                            <div className="button plus">
                                                                <button type="button" className="btn btn-primary btn-number" onClick={increaseQty}
                                                                    data-type="plus" data-field="quant[1]">
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <!--/ End Input Order --> */}
                                                    </div>
                                                    <div className="add-to-cart mt-4">
                                                        <button type="button" onClick={addToCart} className="btn">Add to cart</button>
                                                        <a className="btn min" onClick={() => handleWishlist(productData?.slug, productData?.isWishlist ? 'remove' : 'add')}>
                                                            {productData?.isWishlist && (<i className="fa fa-heart-o" style={{ color: 'red' }}></i>)}
                                                            {!productData?.isWishlist && (<i className="fa fa-heart-o"></i>)}
                                                        </a>
                                                    </div>
                                                </form>

                                                <p className="cat">Category :<a
                                                    href="/product-cat/chains">{productData?.category?.title}</a></p>
                                                <p className="availability">Gross Weight : <span className="badge badge-success">{productData.grossWeight}</span></p>
                                                <p className="availability">{productData?.materialType} Purity: 
                                                    <span>{productData?.karat} Karat</span>
                                                </p>

                                            </div>
                                            {/* <!--/ End Product Buy --> */}

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-info">
                                            <div className="nav-main">
                                                {/* <!-- Tab Nav --> */}
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item"><a className={`nav-link ${activeTab === 'description' ? 'active' : ''}`} data-toggle="tab"
                                                        href="#description" role="tab" onClick={() => handleTabClick('description')}>Description</a></li>
                                                    <li className="nav-item"><a className={`nav-link ${activeTab === 'reviews' ? 'active' : ''}`} data-toggle="tab" href="#reviews"
                                                        role="tab" onClick={() => handleTabClick('reviews')}>Reviews</a></li>
                                                </ul>
                                                {/* <!--/ End Tab Nav --> */}
                                            </div>
                                            {activeTab === 'description' && (
                                                <div className="tab-content" id="myTabContent">
                                                    {/* <!-- Description Tab --> */}

                                                    <div className="tab-pane fade show active" id="description" role="tabpanel">
                                                        <div className="tab-single">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="single-des">
                                                                        <p>
                                                                            {productData?.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></div>)}

                                            {/* <!--/ End Description Tab --> */}
                                            {/* <!-- Reviews Tab --> */}
                                            {activeTab === 'reviews' && (
                                                <div className="tab-content" id="myTabContent">
                                                    <div className=" " id="reviews" role="tabpanel">
                                                        <div className="tab-single review-panel">
                                                            <div className="row">
                                                                <div className="col-12">

                                                                    {/* <!-- Review --> */}
                                                                    <div className="comment-review">
                                                                        <h4>Your Rating <span className="text-danger">*</span></h4>
                                                                        <div className="review-inner">
                                                                            {/* <!-- Form --> */}

                                                                            {
                                                                                !isLoggedIn && (
                                                                                    <p className="text-center p-5">
                                                                                        You need to <a href="/login"
                                                                                            style={{ color: "rgb(54, 54, 204)" }}>Login</a> OR <a
                                                                                                style={{ color: "blue" }}
                                                                                                href="/register">Register</a>

                                                                                    </p>
                                                                                )
                                                                            }
                                                                            {
                                                                                isLoggedIn && (
                                                                                    <><p className="text-center p-5">
                                                                                        <button onClick={() => handleShowModal(productData)}>Add review</button>
                                                                                    </p><div className="review-list">
                                                                                            {reviews.map((review) => (
                                                                                                <div className="review-item" key={review?._id}>
                                                                                                    <h4>
                                                                                                        {review?.name}
                                                                                                        <span>
                                                                                                            {Array.from({
                                                                                                                length: review?.rating,
                                                                                                            }).map((_, index) => (
                                                                                                                <i
                                                                                                                    key={index}
                                                                                                                    className="fa fa-star"
                                                                                                                ></i>
                                                                                                            ))}
                                                                                                        </span>
                                                                                                    </h4>
                                                                                                    <p>{review.comment}</p>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div></>
                                                                                )
                                                                            }
                                                                            {/* <!--/ End Form --> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="ratting-main">
                                                                        <div className="avg-ratting">

                                                                            <h4>0 <span>(Overall)</span></h4>
                                                                            <span>Based on 0 Comments</span>
                                                                        </div>
                                                                    </div>

                                                                    {/* <!--/ End Review --> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                            {/* <!--/ End Reviews Tab --> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Start Most Popular --> */}
            <ProductCarousel title="Related Products" description="" products={products} />
            {/* <!-- End Most Popular Area --> */}
            <ReviewModel showModal={handleShowModal} handleCloseModal={handleCloseModal} modalContent={modalContent} productSlug={productData?.slug} />
        </div>
    );
};

export default ProductDetail;
