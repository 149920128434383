import axios from 'axios';
import { BASE_URL, getAuthInfo } from './authService';

export const getReviewWithPagination = async (currentPage, limit = 10, query) => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        console.log("authInfo inside getReviewWithPagination", authInfo)
        const { data } = await axios.get(`${BASE_URL}/review/get_with_paginate`, {
            params: {
                currentPage: currentPage,
                limit: limit,
                ...query
            },
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const createReview = async (body) => {
    try {
        let authInfo = await getAuthInfo();
        const { data } = await axios.post(`${BASE_URL}/review/create`,{
            name:body?.name,
            comment:body?.comment,
            rating: body?.rating,
            productId: body?.productId
        },{
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


