import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../imageSlider/imageSlider.css';


const DynamicImageSlider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,          
    autoplaySpeed: 2000,     
    arrows: true,            
  };

  return (
    <Slider {...settings} id="Gslider">
      {slides.map((slide, index) => (
        <div key={index} style={{  position: 'relative' }}>
          <img
            className="d-block w-100"
            src={slide?.image}
            alt={`Slide ${index + 1}`}
            style={{  height:"60vh", width: '100%'}}
          />
          <div
            className="slick-caption"
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '20px',
              color: '#fff',
            }}
          >
            <h1 className="wow fadeInDown">{slide?.title}</h1>
            <p>{slide?.description}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default DynamicImageSlider;
