import axios from 'axios';
import { BASE_URL, getAuthInfo } from './authService';

export const getWishlist = async () => {
    try {
        let authInfo = await getAuthInfo();
        const { data } = await axios.get(`${BASE_URL}/wishlist/get`, {
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const updateWishlist = async (body) => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        const { data } = await axios.put(`${BASE_URL}/wishlist/update`,body,{
            headers:{
                Authorization:authInfo?.token
            } 
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};