import React, { useState, useEffect } from 'react';
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { getWishlist } from '../../services/wishlistService';

const Wishlist = () => {
    const [wishlist,setWishlist]= useState()
    const getAll = async () => {
        try {
          const response = await getWishlist()
          const { data } = response;
          setWishlist(data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      useEffect(() => {
        getAll();
    }, []);
    // Data for the table rows if available
    const tableRows = wishlist?.products?.length > 0 ? (
        wishlist?.products?.map((item, index) => (
            <tr key={index}>
                <td className="text-center">{item?.product?.title}</td>
                <td className="text-center">{item?.product?.price}</td>
                <td className="text-center"><i className="fa fa-plus"></i></td>
                <td className="text-center"><i className="fas fa-trash-o"></i></td>
            </tr>
        ))
    ) : (
        <tr>
            <td className="text-center" colSpan="6">
                There are no carts available. <a href="/product-grids" style={{ color: "blue" }}>Continue shopping</a>
            </td>
        </tr>
    );


    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li><a href="home">Home<i className="fas fa-arrow-right"></i></a></li>
                                    <li className="active"><a href="">Wishlist</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumbs --> */}
            {/* <!-- Shopping Cart --> */}
            <div className="shopping-cart section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Shopping Summery --> */}
                            <table className="table shopping-summery">
                                <thead style={{width:"100%"}}>
                                    <tr className="main-hading">
                                        <th style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>PRODUCT</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>PRICE</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>ADD TO CART</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}><i className="fas fa-trash-o"></i></th>
                                    </tr>
                                </thead>
                                <tbody id="cart_item_list">
                {tableRows}
            </tbody>
                            </table>
                            {/* <!--/ End Shopping Summery --> */}
                        </div>
                    </div>
                     
                </div>
            </div>
            
            {/* <!--/ End Shopping Cart --> */}
        </div>


    );
};

export default Wishlist;
