import { Modal } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect,useState } from 'react';
import { createReview } from '../../services/reviewService';

const ReviewModal = ({ showModal, handleCloseModal, modalContent,productSlug }) => {

    const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  useEffect(() => {
    // Log the props when they change
  }, [showModal, handleCloseModal, modalContent, reviews]);
  if (!modalContent) return null;
  
  const handleNameChange = (e) => setName(e.target.value);
    const handleTextChange = (e) => setComment(e.target.value);
    const handleRatingChange = (newRating) => setRating(newRating);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (name && comment && rating) {
            const newReview = { id: reviews.length + 1, name, comment, rating, productId:productSlug };
    
            // Post the review to the server and update state after success
            try {
                const result = await createReview(newReview);
                setReviews([...reviews, newReview]);
                setName('');
                setComment('');
                setRating(0);
                handleCloseModal(); // Close the modal after submission
            } catch (error) {
                console.error('Failed to submit review:', error);
            }
        } else {
            console.log('All fields are required');
        }     
        console.log('reviews',reviews);
    };    

  
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
        <Modal.Title>Write a Review</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="review-container">
            <form className="review-form" onSubmit={handleSubmit}>
            <div className="form-group">
            <label htmlFor="name">Username: </label>
                <input
                   type="text"
                   id="name"
                   placeholder="Your Name"
                   value={name}
                   onChange={handleNameChange}
                   required
                />
                </div>
                <div className="form-group">
                <textarea
                    placeholder="Write your review..."
                    value={comment}
                    onChange={handleTextChange}
                    rows="4"
                    required
                ></textarea>
                </div>
                <div className="star-rating">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <i
                                    key={star}
                                    className={`fa ${
                                        rating >= star
                                            ? 'fa-star'
                                            : 'fa-star-o'
                                    }`}
                                    onClick={() =>
                                        handleRatingChange(star)
                                    }
                                    style={{ cursor: 'pointer', color: rating >= star ? '#F7941D' : 'black' }}
                                ></i>
                            ))}
                        </div>
                <button type="submit">Submit Review</button>
            </form>
            <div className="review-list">
                {reviews.map((review) => (
                    <div className="review-item" key={review?._id}>
                        <h4>
                            {review?.name}
                            <span>
                                {Array.from({
                                    length: review?.rating,
                                }).map((_, index) => (
                                    <i
                                        key={index}
                                        className="fa fa-star"
                                    ></i>
                                ))}
                            </span>
                        </h4>
                        <p>{review?.description}</p>
                    </div>
                ))}
            </div>
        </div>
    </Modal.Body>
</Modal>
  );
};

export default ReviewModal;
