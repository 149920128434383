import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import '@fortawesome/fontawesome-free/css/all.min.css';
import "../src/css/responsive.css";
 import "../src/css/style.css";
import "../src/css/animate.css";
// import "./css/bootstrap.css";
import "../src/css/flex-slider.min.css";
// import "./css/font-awesome.css";
// import "./css/jquery-ui.css";  
// import "../src/components/css/jquery.fancybox.min.css";
import "../src/css/magnific-popup.min.css";
import "../src/css/niceselect.css";
// import "./css/owl-carousel.css";
import "../src/css/reset.css";
import "../src/css/responsive.css";
import "../src/css/bootstrap.css";
// import "./css/themify-icons.css";











// import "../components/css/responsive.css";
// import "../components/css/style.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
