import axios from 'axios';
import { BASE_URL, getAuthInfo } from './authService';

export const getProductWithPagination = async (currentPage, limit = 10, query) => {
    try {
        const authInfo = await getAuthInfo();
        const headers = authInfo?.token ? { Authorization: authInfo.token } : {};

        const { data } = await axios.get(`${BASE_URL}/product/get_with_paginate`, {
            params: {
                currentPage: currentPage,
                limit: limit,
                ...query
            },
            headers: headers,
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getProductById = async (product) => {
    try {
        const authInfo = await getAuthInfo();
        const headers = authInfo?.token ? { Authorization: authInfo.token } : {};
        const { data } = await axios.get(`${BASE_URL}/product/${product}`, {
            headers: headers,
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getTopSellingProducts = async (currentPage, limit = 10) => {
    try {
        const authInfo = await getAuthInfo();
        const headers = authInfo?.token ? { Authorization: authInfo.token } : {};
        const { data } = await axios.get(`${BASE_URL}/product/getTopSellingProducts`, {
            params: {
                currentPage: currentPage,
                limit: limit
            },
            headers: headers,
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getTodaySellingProducts = async (currentPage, limit = 10) => {
    try {
        const authInfo = await getAuthInfo();
        const headers = authInfo?.token ? { Authorization: authInfo.token } : {};
        const { data } = await axios.get(`${BASE_URL}/product/getTodaySellingProducts`, {
            params: {
                currentPage: currentPage,
                limit: limit
            },
            headers: headers,
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
