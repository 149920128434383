import React from 'react';
import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';

const MainLayout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <main className="main-content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
