import axios from 'axios';
import { BASE_URL } from './authService';

export const getCategoryWithPagination = async (currentPage, limit = 10,query) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/category/get_with_paginate`, {
            params: {
                currentPage: currentPage,
                limit: limit,
                ...query
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getCategoryWithChildren = async () => {
    try {
        const { data } = await axios.get(`${BASE_URL}/category/with_children`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getCategoryById = async (category) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/category/${category}`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
