import React from 'react';
import "./../../css/responsive.css";
import "./../../css/bootstrap.css";
import "./../../css/style.css";

const Faq = () => {

    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
    <div className="breadcrumbs">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="bread-inner">
                        <ul className="bread-list">
                            <li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
                            <li className="active"><a href="javascript:void(0);">About Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Breadcrumbs --> */}
    {/* <!-- About Us --> */}
	<section className="about-us section">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-12">
						<div className="about-content">
														<h3>Welcome To <span>SRH JEWELLERY</span></h3>
							<p>Where Tradition Meets Elegance.

For generations, our family has been crafting exquisite gold and silver jewellry, bringing together timeless artistry and contemporary design. Since our official launch in 2018, we've made it our mission to offer stunning pieces that embody the perfect blend of heritage and modernity.

At SRH JEWELLERY , we believe that jewellry is more than just an accessory; it’s a reflection of your style, your milestones, and your memories. That’s why every piece in our collection is thoughtfully designed and meticulously crafted to make luxury accessible for everyone.

Our journey from a traditional family business to an online destination allows us to reach jewellry lovers far and wide, offering the same personalized experience and quality craftsmanship that our customers have cherished for years. Whether you’re looking for a timeless classic or a bold, contemporary statement piece, you’ll find it here.

We invite you to explore our collection and discover the perfect piece that resonates with your story. At SRH JEWELLERY , we’re not just selling jewellry – we’re sharing our legacy with you.

Indulge in the beauty of fine jewellry and let us be a part of your journey. </p>
							<div className="button">
								<a href="/blog" className="btn">Our Blog</a>
								<a href="/contact" className="btn primary">Contact Us</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-12">
						<div className="about-img overlay">
							<div className="button">
								<a href="https://www.youtube.com/watch?v=Rdpy5mWprXk" className="video video-popup mfp-iframe"><i className="fa fa-play"></i></a>
							</div>
							<img src=" /public/photos/blog/blog3.png " alt=" /public/photos/blog/blog3.png "/>
						</div>
					</div>
				</div>
			</div>
	</section>
	{/* <!-- End About Us --> */}


	{/* <!-- Start Shop Services Area --> */}
	<section className="shop-services section">
		<div className="container">
			<div className="row">
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<i className="fas fa-rocket"></i>
						<h4>Free shiping</h4>
						<p>Orders over $100</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<i className="fas fa-sync-alt"></i>
						<h4>Free Return</h4>
						<p>Within 30 days returns</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<i className="fas fa-lock"></i>
						<h4>Sucure Payment</h4>
						<p>100% secure payment</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<i className="fas fa-tag"></i>
						<h4>Best Peice</h4>
						<p>Guaranteed price</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
			</div>
		</div>
	</section>
	{/* <!-- End Shop Services Area --> */}

	{/* <!-- Start Shop Newsletter  --> */}
<section className="shop-newsletter section">
    <div className="container">
        <div className="inner-top">
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-12">
                    {/* <!-- Start Newsletter Inner --> */}
                    <div className="inner">
                        <h4>Newsletter</h4>
                        <p> Subscribe to our newsletter and get <span>10%</span> off your first purchase</p>
                        <form action="/subscribe" method="post" className="newsletter-inner">
                            <input type="hidden" name="_token" value="Sin5WiVKfgn3E386VRkz7p5IONQ33i62W00avwEm"/>                            <input name="email" placeholder="Your email address" required="" type="email"/>
                            <button className="btn" type="submit">Subscribe</button>
                        </form>
                    </div>
                    {/* <!-- End Newsletter Inner --> */}
                </div>
            </div>
        </div>
    </div>
</section>
{/* <!-- End Shop Newsletter --> */}
            </div>

         
    );
};

export default Faq;
