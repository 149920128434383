import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/homePage/homePage.js';
import ProductDetail from './components/product-deatil/product-detail.js';
import Cart from './components/addCart/addCart.js';
import MainLayout from './layout/mainLayout';
import Login from './components/login/login.js'; 
import Register from './components/register/register.js';
import Contact from './components/contact/contact.js';
import About from './components/about/about.js';
import Wishlist from './components/wishList/wishList.js';
import ProductGrid from './components/product-grids/product-grids.js';
import CheckOut from './components/checkOut/checkOut.js';
import "./css/responsive.css";
import "./css/style.css";
import "./css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getProfileInfo } from './services/authService.js';
import TermsCondition from './components/terms-condition/terms-condition.js';
import Faq from './components/faq/faq.js';
import PrivacyPolicy from './components/privacy-policy/privacy-policy.js';

const App = () => {
  const getProfileUpdate = async () => {
    await getProfileInfo();
  }

  useEffect(() => {
    getProfileUpdate();
  });
  return (
    <Router>
      <MainLayout>
      
      
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product-detail/:productName" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<About />} />
          <Route path="/product-grids" element={<ProductGrid />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/checkout" element={<CheckOut />} />


          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      
    
        
      </MainLayout>
    </Router>
  );
};

export default App;
