import axios from 'axios';
import { BASE_URL } from './authService';

export const getAllBanner = async () => {
    try {
        const { data } = await axios.get(`${BASE_URL}/banner/all`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};