
import React, { useState } from 'react';
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { loginUser } from '../../services/authService';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [loginObj, setLoginObj] = useState({email: "", password: ""});
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    const handleLogin = async (e) => {
      e.preventDefault();
     console.log("e",e);
      try {
        const response = await loginUser( loginObj?.email, loginObj?.password );
        navigate(-1);
      } catch (err) {
        // Handle error
        setError('Login failed. Please check your credentials.');
        console.error('Login error:', err);
      }
    };
  
    const handleChange = async (e, field) => {
        setLoginObj({...loginObj, [field]: e.target.value})
    };
  
    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
    <div className="breadcrumbs">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="bread-inner">
                        <ul className="bread-list">
                            <li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
                            <li className="active"><a href="javascript:void(0);">Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Breadcrumbs --> */}
    {/* <!-- Shop Login --> */}
    <section className="shop login section">
        <div className="container">
            <div className="row"> 
                <div className="col-lg-6 offset-lg-3 col-12">
                    <div className="login-form">
                        <h2>Login</h2>
                        {/* <!-- Form --> */}
                        <form className="form" onSubmit={handleLogin}>                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Your Email<span>*</span></label>
                                        <input type="email" name="email" placeholder="" required="required" onChange={e => handleChange(e, 'email')}/>
                                </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Your Password<span>*</span></label>
                                        <input type="password" name="password" placeholder="" required="required" onChange={e => handleChange(e, 'password')} />
                                </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group login-btn">
                                        <button className="btn btn-facebook" type="submit">Login</button>
                                        <a href="/register" className="btn">Register</a>
                                    </div>
                                    <div className="checkbox">
                                        <label className="checkbox-inline" for="2">
                                            <input id="2" type="checkbox"/>Remember me
                                        </label>
                                    </div>
                                    <a className="lost-pass" href="/password-reset">
                                            Lost your password?
                                        </a>
                                                                    </div>
                            </div>
                        </form>
                        {/* <!--/ End Form --> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--/ End Login --> */}
            </div>

         
    );
};

export default Login;
